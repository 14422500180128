import React, { useEffect, useState, useContext } from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import * as style from 'styles/components/match/item.module.scss';
import { LangContext } from 'context/LangContext';
import { RoutesContext } from 'context/RoutesContext';
import CustomLink from 'components/utils/CustomLink';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';
import { MATCH_STATUS } from "data/constants";
import TrackableCTA from 'components/utils/TrackableCTA';
// import BuyTickets from 'templates/buyTickets';

const MatchItem = ({ match, isMobileFormat = true, isHorizontalFormat = false }) => {
    const { lang } = useContext(LangContext);
    const { routes } = useContext(RoutesContext) || { routes: [] };

    const [href, setHref] = useState('');

    useEffect(() => {
        if (routes && routes.length !== 0) {
            setHref(
                `/${
                    routes.find((r) => r.node.name === 'matchList' && r.node.lang === lang.hreflang)
                        .node.path
                }/${match.slug.current}`,
            );
        }
    }, [lang, routes, match.slug]);
    const date = new Date(match.startEventDate);
    const formattedDate = date.toLocaleDateString('sv-SE', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });
    const formattedHour = date.toLocaleTimeString('sv-SE', {
        timeStyle: 'short',
    });

    const buildFormattedHourSe = () => formattedHour.replace(":", '.');
    const buildFormattedDateSe = () => formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

    const AXS_LINK = match.ticketsUrl;
    const SALES_START_DATE = new Date(match.ticketsSellStartTime);
    const MATCH_DATE = new Date(match.startEventDate);
    const SALES_SOLD_OUT = match.ticketsSoldOut;
    const FREE_ADMISSION = match.freeAdmission;
    const IS_CUSTOM_TEXT = match.isCustomText;
    const CUSTOM_TEXT = match.customText;

    const buildButton = () => {
        if (match.matchStatus === MATCH_STATUS.FINISHED) return null;

        if (IS_CUSTOM_TEXT) {
            if( !CUSTOM_TEXT ) {
                return (
                    <button type="button" className="inactive cta-white" aria-label={getFormattedMessage('match.notForSale', lang)}>
                        <FormattedMessage id="match.notForSale" />
                    </button>
                );
            }
            return (
                <button type="button" className="inactive cta-white" aria-label={CUSTOM_TEXT}>
                    {CUSTOM_TEXT}
                </button>
            );
        }

        if (FREE_ADMISSION) {
            return (
                <button type="button" className="inactive cta-white" aria-label={getFormattedMessage('match.freeAdmission', lang)}>
                    <FormattedMessage id="match.freeAdmission" />
                </button>
            );
        }

        if (SALES_SOLD_OUT || SALES_START_DATE > MATCH_DATE) {
            return (
                <button type="button" className="inactive cta-white" aria-label={getFormattedMessage('match.soldOut', lang)}>
                    <FormattedMessage id="match.soldOut" />
                </button>
            );
        }

        if (SALES_START_DATE && SALES_START_DATE < MATCH_DATE) {
            if (SALES_START_DATE > new Date()) {
                return (
                    <button type="button" className="inactive cta-white" aria-label={getFormattedMessage('buytickets.salesStart', lang)}>
                        <FormattedMessage id="buytickets.salesStart" />:{' '}
                        {SALES_START_DATE.toLocaleDateString('sv-SE', {
                            day: 'numeric',
                            month: 'short',
                        })}
                    </button>
                );
            }
            if (AXS_LINK) {
                return (
                    <TrackableCTA
                        ctaText={`${getFormattedMessage('match.buyTicket', lang)}: ${match.local.title} - ${match.external.title}`}
                        location="matchitem"
                        destination={AXS_LINK}
                    >
                        <a href={AXS_LINK} className="cta-white" target="_blank" rel="noreferrer" aria-label={getFormattedMessage('match.buyTicket', lang)}>
                            <FormattedMessage id="match.buyTicket" />
                        </a>
                    </TrackableCTA>
                );
            }
        }
        return (
            <button type="button" className="inactive cta-white" aria-label={getFormattedMessage('match.notForSale', lang)}>
                <FormattedMessage id="match.notForSale" />
            </button>
        );
    };

    if (isHorizontalFormat) {
        return (
            <div
                className={`${style.matchHorizontal} ${
                    match.matchStatus === MATCH_STATUS.FINISHED ? style.matchHorizontalFinished : ''
                } bg`}
            >
                <CustomLink to={href} className={style.matchHorizontal__link} />
                <div
                    className={`${style.matchHorizontal__content} ${
                        match.matchStatus !== MATCH_STATUS.FINISHED && style.matchHorizontal__contentWithButton
                    }`}
                >
                    <div className={style.matchHorizontal__left}>
                        <p className={style.matchHorizontal__league}>
                            {match.competition?.title}
                        </p>

                        {match.ticketsSold && (
                            <p className={`${style.match__loc} hidden-desktop text-xs text-medium uppercase text-darker`}>
                                <FormattedMessage id="match.soldTotal" /> {match.ticketsSold.toLocaleString()}
                            </p>
                        )}

                        <p className="text-lg text-semibold">{buildFormattedDateSe()}</p>
                        <p className="text-xs text-medium uppercase text-darker">
                            {match.location} - {buildFormattedHourSe()}
                        </p>
                    </div>

                    <div className={style.matchHorizontal__center}>
                        <div className={style.matchHorizontal__logoContainer}>
                            <div className={style.matchHorizontal__logo}>
                                {match.local.image && (
                                    <ImageGatsby
                                        {...match.local.image}
                                        alt={match.local.title}
                                        title={match.local.title}
                                        width={60}
                                    />
                                )}
                            </div>
                            <span className={`${style.matchHorizontal__logoContainer__teamTitle} text-semibold`}>
                                {match.local.title}
                            </span>
                        </div>

                        <div>
                            {match.matchStatus === MATCH_STATUS.FINISHED ? (
                                <div className={style.matchHorizontal__scores}>
                                    <div className={style.matchHorizontal__scores__scoreContainer}>
                                        <span
                                            className={`text-lg text-bold ${
                                                style.matchHorizontal__score
                                            } ${
                                                match.local.isHammarby
                                                    ? style.matchHorizontal__scoreHammarby
                                                    : ''
                                            }`}
                                        >
                                            {match.localScore}
                                        </span>
                                    </div>
                                    <div className={style.matchHorizontal__scores__scoreContainer}>
                                        <span
                                            className={`text-lg text-bold ${
                                                style.matchHorizontal__score
                                            } ${
                                                match.external.isHammarby
                                                    ? style.matchHorizontal__scoreHammarby
                                                    : ''
                                            }`}
                                        >
                                            {match.externalScore}
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <p className="text-xs text-medium uppercase text-darker">–</p>
                            )}
                        </div>
                        <div className={style.matchHorizontal__logoContainer}>
                            <div className={style.matchHorizontal__logo}>
                                {match.external.image && (
                                    <ImageGatsby
                                        {...match.external.image}
                                        alt={match.external.title}
                                        title={match.external.title}
                                        width={60}
                                    />
                                )}
                            </div>
                            <span className={`${style.matchHorizontal__logoContainer__teamTitle} text-semibold`}>
                                {match.external.title}
                            </span>
                        </div>
                    </div>
                    <div className={style.matchHorizontal__right}>
                        {match.matchStatus !== MATCH_STATUS.FINISHED && buildButton()}
                        {match.ticketsSold && (
                            <p
                            className={`${style.match__loc} hidden-mobile text-xs text-medium uppercase text-darker center`}
                            >
                                <FormattedMessage id="match.soldTotal" /> {match.ticketsSold.toLocaleString()}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div
            className={`${style.match} ${
                match.matchStatus === MATCH_STATUS.FINISHED ? style.matchHorizontalFinished : ''
            } bg`}
        >
            <CustomLink to={href} className={style.matchHorizontal__link} />

            <div className="full-width">
                <p className={style.matchHorizontal__league}>{match.competition?.title}</p>
                <p
                    className={`${
                        isMobileFormat ? 'text-md' : 'text-md-mobile text-xl'
                    } text-semibold`}
                >
                    {buildFormattedDateSe()}
                </p>
                <p
                    className={`${style.match__loc} ${
                        isMobileFormat ? 'text-xs' : 'text-xs-mobile text-sm'
                    } text-medium uppercase text-darker`}
                >
                    {match.location}
                </p>
            </div>

            <div className={`full-width ${style.match__middle}`}>
                <div className={style.match__logos}>
                    <div>
                        {match.local.image && (
                            <ImageGatsby
                                {...match.local.image}
                                alt={match.local.title}
                                title={match.local.title}
                                width={60}
                            />
                        )}
                    </div>
                    <p
                        className={`${
                            isMobileFormat ? 'title-xs' : 'title-xs-mobile title-sm'
                        } text-bold`}
                    >
                        {buildFormattedHourSe()}
                    </p>
                    <div>
                        {match.external.image && (
                            <ImageGatsby
                                {...match.external.image}
                                alt={match.external.title}
                                title={match.external.title}
                                width={60}
                            />
                        )}
                    </div>
                </div>
                <div className={style.match__names}>
                    <p
                        className={`${
                            isMobileFormat ? 'text-sm' : 'text-sm-mobile text-lg'
                        } text-semibold`}
                    >
                        {match.local.title}
                        <span
                            className={`${style.match__names__separator} text-xs text-medium uppercase text-darker`}
                        >
                            –
                        </span>
                        {match.external.title}
                    </p>

                </div>
            </div>

            <div className={style.match__bottom}>
                {buildButton()}
                <p
                    className={`${style.match__loc} ${
                        isMobileFormat ? 'text-xs' : 'text-xs-mobile text-sm'
                    } text-medium uppercase text-darker`}
                >
                    {match.ticketsSold && (
                        <>
                            <FormattedMessage id="match.soldTotal" /> {match.ticketsSold.toLocaleString()}
                        </>
                    )}
                        {!match.ticketsSold && (
                        <>
                                &nbsp;
                        </>
                        )}
                </p>

            </div>
        </div>
    );
};

export default MatchItem;
